import { AlertsRepository } from '@/io/repository/AlertsRepository';
import { ManagePatientRepository } from '@/io/repository/ManagePatientRepository';
import { ObservationsRepository } from '@/io/repository/ObservationsRepository';
import { PractitionerRepository } from '@/io/repository/PractitionerRepository';
import { UploadFileRepository } from '@/io/repository/UploadFileRepository';
import {
  PatientRenewalDecision,
  TelemonitoringTags,
} from '@/models/CarePlanModel';
import { CreatePatientModel } from '@/models/CreatePatientModel';
import { EditTherapeuticObjectivesData } from '@/models/EditTherapeuticObjectivesModel';
import { PatientList } from '@/models/PatientModel';
import { UpdatePractitioner } from '@/models/PractitionerModel';
import { RenewCarePlanData } from '@/models/RenewCarePlanModel';
import { UploadFileModel } from '@/models/UploadFileModel';
import { ValidateOrRejectIdentity } from '@/models/identity/FrenchIdentity';
import { ContactData } from '@/pages/patient-medical-file/contact-patient/ContactPatient.schema';
import { Queries, makeQueryKey, stripQueryResult } from '@/queries/Queries';
import { createMutation } from '@/queries/utils/CreateMutation';
import { createQuery } from '@/queries/utils/CreateQuery';

export class PractitionerQueries {
  constructor(
    private readonly practitioner: PractitionerRepository = new PractitionerRepository(),
    private readonly managePatient: ManagePatientRepository = new ManagePatientRepository(),
    private readonly observation: ObservationsRepository = new ObservationsRepository(),
    private readonly alerts: AlertsRepository = new AlertsRepository(),
    private readonly uploadFile: UploadFileRepository = new UploadFileRepository(),
  ) {}

  /*******************************/
  /*********** QUERIES ***********/
  /*******************************/

  usePractitioner = createQuery(
    'practitioner',
    ({}) => [],
    async () => stripQueryResult(await this.practitioner.getPractitioner()),
  );

  usePatientsList = createQuery(
    'practitioner-patients',
    ({}) => [],
    async () => {
      const data = stripQueryResult(await this.managePatient.getPatientList());
      const priorities: Record<TelemonitoringTags, number> = {
        expiring_soon: 1,
        active: 2,
        gestational_no_insulin: 3,
        no_glycemia_data: 4,
        expired_recently: 5,
        expired: 6,
      };

      const getPriority = (patient: PatientList) => {
        let maxPriority = -Infinity;
        for (const tag of patient.telemonitoring_tags) {
          if (priorities[tag] && priorities[tag] > maxPriority) {
            maxPriority = priorities[tag];
          }
        }
        return maxPriority === -Infinity
          ? Number.MIN_SAFE_INTEGER
          : maxPriority;
      };

      data.sort((a, b) => {
        const priorityA = getPriority(a);
        const priorityB = getPriority(b);

        if (priorityA !== priorityB) {
          return priorityA - priorityB;
        }

        return a.familyName.localeCompare(b.familyName);
      });
      return data;
    },
    {
      staleTime: 30_000,
    },
  );

  usePatient = createQuery(
    'observed-patient',
    patientId => [patientId],
    async (patientId: string) =>
      stripQueryResult(await this.managePatient.getPatient(patientId)),
    {
      staleTime: 30_000,
    },
  );

  useIdentitiesToValidate = createQuery(
    'identities-to-validate',
    ({}) => [],
    async () =>
      stripQueryResult(await this.managePatient.getIdentitiesToValidate()),
  );

  useNurseNotice = createQuery(
    'nurse-notice',
    patientId => makeQueryKey(patientId),
    async (patientId: string) =>
      stripQueryResult(await this.observation.getNurseNotice(patientId)),
  );

  useCareTeam = createQuery(
    'careTeam',
    patientId => makeQueryKey(patientId),
    async (patientId: string) =>
      stripQueryResult(await this.managePatient.getCareTeam(patientId)),
  );

  useCarePlans = createQuery(
    'carePlans',
    patientId => makeQueryKey(patientId),
    async (patientId: string) =>
      stripQueryResult(await this.managePatient.getCarePlans(patientId)),
  );

  useCarePlanDirectives = createQuery(
    'carePlanDirectives',
    ({ patientId }) => makeQueryKey(patientId),
    async (data: { patientId?: string }) =>
      stripQueryResult(
        await this.managePatient.getCarePlanDirectives(data.patientId),
      ),
  );

  useGetPatientMessageCount = createQuery(
    'patient-message-count',
    patientId => makeQueryKey(patientId),
    async (patientId: string) =>
      stripQueryResult(
        await this.managePatient.getPatientMessageCount(patientId),
      ),
  );

  useListPatientAlerts = createQuery(
    'patient-alerts',
    patientId => makeQueryKey(patientId),
    async (patientId: string) =>
      stripQueryResult(await this.alerts.getAlertList(patientId)),
  );

  useListContacts = createQuery(
    'patient-contacts',
    patientId => makeQueryKey(patientId),
    async (patientId: string) =>
      stripQueryResult(await this.managePatient.getListContacts(patientId)),
  );

  useGlycemiaDisplayMode = createQuery(
    'glycemia-display-mode',
    patientId => makeQueryKey(patientId),
    async (patientId: string) =>
      stripQueryResult(
        await this.managePatient.getGlycemiaDisplayMode(patientId),
      ),
  );

  /*******************************/
  /********** MUTATIONS **********/
  /*******************************/

  useCreatePatient = createMutation(
    'create-patient',
    async (data: CreatePatientModel) => {
      return stripQueryResult(await this.managePatient.createPatient(data));
    },
    {
      onSuccess: async (_, __, { queryClient }) => {
        this.usePatientsList.invalidate(queryClient, {});
      },
    },
  );

  useRenewCarePlan = createMutation(
    'renew-care-plan',
    async ({
      patientId,
      data,
    }: {
      patientId: string;
      data: RenewCarePlanData;
    }) =>
      stripQueryResult(await this.managePatient.renewCarePlan(patientId, data)),
    {
      onSuccess: async (_, { patientId }, { queryClient }) => {
        this.useCarePlans.invalidate(queryClient, patientId);
        this.usePatientsList.invalidate(queryClient, {});
        Queries.condition.useCondition.invalidate(queryClient, patientId);
        this.usePatient.invalidate(queryClient, patientId);
        setTimeout(() => {
          this.useCarePlanDirectives.invalidate(queryClient, { patientId });
        }, 1000);
      },
    },
  );

  useEditTherapeuticObjectives = createMutation(
    'edit-therapeutic-objectives',
    async ({
      carePlanId,
      data,
    }: {
      carePlanId: string;
      data: EditTherapeuticObjectivesData;
    }) =>
      stripQueryResult(
        await this.managePatient.editTherapeuticObjectives(carePlanId, data),
      ),
    {
      onSuccess: async (data, {}, { queryClient }) => {
        this.useCarePlans.invalidate(queryClient, data.id);
      },
    },
  );

  useUpdatePractitioner = createMutation(
    'update-practitioner',
    async (data: UpdatePractitioner) =>
      stripQueryResult(await this.practitioner.updatePractitioner(data)),
    {
      onSuccess: async (practitioner, _, { queryClient }) =>
        this.usePractitioner.manualUpdate(queryClient, {}, () => practitioner),
    },
  );

  useValidateOrRejectIdentity = createMutation(
    'validate-or-reject-identity',
    async ({
      patientId,
      action,
    }: {
      patientId: string;
      action: ValidateOrRejectIdentity;
    }) =>
      stripQueryResult(
        await this.managePatient.validateOrRejectIdentity(patientId, action),
      ),
    {
      onSuccess: async (_, { patientId }, { queryClient }) => {
        this.useIdentitiesToValidate.invalidate(queryClient, {});
        this.usePatient.invalidate(queryClient, patientId);
      },
    },
  );

  useCreateContact = createMutation(
    'create-contact',
    async ({ patientId, data }: { patientId: string; data: ContactData }) =>
      stripQueryResult(
        await this.managePatient.createPatientContacts(patientId, data),
      ),
    {
      onSuccess: (_, { patientId }, { queryClient }) => {
        this.useListContacts.invalidate(queryClient, patientId);
      },
    },
  );

  useUpdateContact = createMutation(
    'update-contact',
    async ({
      patientId,
      contactId,
      data,
    }: {
      patientId: string;
      contactId: string;
      data: Partial<ContactData>;
    }) =>
      stripQueryResult(
        await this.managePatient.updatePatientContacts(
          patientId,
          contactId,
          data,
        ),
      ),
    {
      onSuccess: (_, { patientId }, { queryClient }) => {
        this.useListContacts.invalidate(queryClient, patientId);
      },
    },
  );

  useDeleteContact = createMutation(
    'delete-contact',
    async ({
      patientId,
      contactId,
    }: {
      patientId: string;
      contactId: string;
    }) =>
      stripQueryResult(
        await this.managePatient.deletePatientContacts(patientId, contactId),
      ),
    {
      onSuccess: (_, { patientId }, { queryClient }) => {
        this.useListContacts.invalidate(queryClient, patientId);
      },
    },
  );

  useUploadFile = createMutation('upload-file', async (data: UploadFileModel) =>
    stripQueryResult(await this.uploadFile.uploadFile(data)),
  );

  useDecideRenewCarePlan = createMutation(
    'decide-renew-care-plan',
    async ({
      patientId,
      decision,
    }: {
      patientId: string;
      decision: PatientRenewalDecision;
    }) =>
      stripQueryResult(
        await this.managePatient.decideRenewPatient(patientId, decision),
      ),
    {
      onSuccess: async (_, { patientId }, { queryClient }) => {
        this.useCarePlans.invalidate(queryClient, patientId);
        this.usePatient.invalidate(queryClient, patientId);
      },
    },
  );
}

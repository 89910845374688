import React, { useState } from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { ChatContainer } from '@/components/chat/ChatContainer';
import { ChatStatusContext } from '@/components/chat/ChatContext';
import { Col, Row } from '@/components/layout/Flex';
import { NavItem } from '@/components/navigation/NavItem';
import { PatientNavigation } from '@/components/navigation/PatientNavigation';
import { SideBar } from '@/components/navigation/SideBar';
import { useStyles } from '@/hooks/useStyles';
import { LayoutProps, RenderLayoutChildren } from '@/pages/layout/LayoutCommon';
import { Queries } from '@/queries/Queries';
import { PatientRenewalPopup } from '@/uiKit/molecules/PatientRenewalPopup';

export const HealthProManagePatientLayout: React.FC<LayoutProps> = props => {
  const styles = useStyles(makeStyles);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [chatState, setChatState] = useState(false);
  const { data: practitioner } = Queries.practitioner.usePractitioner({});

  return (
    <Row lang={i18n.language} className={styles.container}>
      <ChatStatusContext.Provider
        value={{ open: chatState, setOpen: setChatState }}
      >
        <SideBar home="/patients">
          <NavItem
            to="/patients"
            isCurrent={
              location.pathname === '/' ||
              location.pathname.startsWith('/patients')
            }
            tooltip={t('navbar.myPatients')}
          />
        </SideBar>
        <Col className={styles.main}>
          <PatientNavigation className={styles.header} />
          <Col className={styles.content} Component="main">
            <RenderLayoutChildren {...props} />
          </Col>
        </Col>
        {chatState && <ChatContainer />}
        {practitioner?.qualification === 'doctor' && <PatientRenewalPopup />}
      </ChatStatusContext.Provider>
    </Row>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    align-items: stretch;
    margin: 0;
    padding: 0;
    max-width: 100vw;
    min-height: 100vh;
    background-color: ${theme.palette.background.default};
    overflow-x: hidden;
  `,
  main: css`
    flex-grow: 1;
    flex-shrink: 1;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  `,
  header: css`
    margin-left: ${theme.spacing(30)};
  `,
  content: css`
    padding: ${theme.spacing(12)} 4vw;
    margin-left: ${theme.spacing(30)};
  `,
});

import { css, cx } from '@emotion/css';
import { Chip, ChipProps, Theme, Typography } from '@mui/material';

import { useStyles } from '@/hooks/useStyles';

export type TagProps = Omit<ChipProps, 'label' | 'size' | 'classes'> & {
  label?: string | React.ReactNode;
  classes?: {
    root?: string;
    label?: string;
    icon?: string;
  };
};

export const Tag: React.FC<TagProps> = ({ label, classes, ...props }) => {
  const styles = useStyles(makeStyles, !!label);

  return (
    <Chip
      label={
        label && typeof label == 'string' ? (
          <Typography variant="caption" classes={{ root: styles.text }}>
            {label}
          </Typography>
        ) : (
          label
        )
      }
      variant="inverted"
      size="small"
      classes={{
        root: cx(styles.root, classes?.root),
        label: cx(styles.label, classes?.label),
        icon: cx(styles.icon, classes?.icon),
      }}
      {...props}
    />
  );
};

const makeStyles = (theme: Theme, hasLabel: boolean) => ({
  root: css`
    border-radius: ${theme.spacing(1)};
  `,
  label: css`
    padding: 0 ${hasLabel ? theme.spacing(4) : theme.spacing(2)};
  `,
  text: css`
    color: inherit;
  `,
  icon: css``,
});

import React from 'react';

import type { Components, Palette, PaletteColor, Theme } from '@mui/material';
import type { TypographyOptions } from '@mui/material/styles/createTypography';

import { FontDefinitions, FontSizes } from '@/assets/fonts';
import { ThemeColor } from '@/theme/types';

declare module '@mui/material/styles' {
  interface PaletteColor {
    background?: string;
  }

  interface Palette {
    primary: PaletteColor;
    red: PaletteColor;
    orange: PaletteColor;
    blue: PaletteColor;
    green: PaletteColor;
    pink: PaletteColor;
    violet: PaletteColor;
    neutral: PaletteColor;
    purple: PaletteColor;
    lightPrimary: PaletteColor;
    ivory: string;
    environment: string;
    glycemia: {
      severeHyperglycemia: PaletteColor;
      hyperglycemia: PaletteColor;
      normal: PaletteColor;
      hypoglycemia: PaletteColor;
      severeHypoglycemia: PaletteColor;
    };
    insulin: {
      basal: PaletteColor;
      bolus: PaletteColor;
    };
    agp: {
      p90: ThemeColor;
      p50: ThemeColor;
      median: ThemeColor;
    };
    bgm: {
      header: ThemeColor;
      clearColumn: ThemeColor;
      darkColumn: ThemeColor;
      orangeDarkBackground: ThemeColor;
      redBackground: ThemeColor;
      orangeBackground: ThemeColor;
      greenBackground: ThemeColor;
      purpleText: ThemeColor;
      activityIcon: ThemeColor;
      purpleBackground: ThemeColor;
    };
  }

  interface SimplePaletteColorOptions {
    background?: string;
  }

  interface CustomPaletteColorOptions {
    background?: string;
    light: string;
    main: string;
    dark: string;
    contrastText: string;
  }

  interface PaletteOptions {
    red: CustomPaletteColorOptions;
    orange: CustomPaletteColorOptions;
    blue: CustomPaletteColorOptions;
    green: CustomPaletteColorOptions;
    pink: CustomPaletteColorOptions;
    violet: CustomPaletteColorOptions;
    purple: CustomPaletteColorOptions;
    neutral: CustomPaletteColorOptions;
    lightPrimary: CustomPaletteColorOptions;
    ivory: string;
    environment: string;
    glycemia: {
      severeHyperglycemia: CustomPaletteColorOptions;
      hyperglycemia: CustomPaletteColorOptions;
      normal: CustomPaletteColorOptions;
      hypoglycemia: CustomPaletteColorOptions;
      severeHypoglycemia: CustomPaletteColorOptions;
    };
    insulin: {
      basal: CustomPaletteColorOptions;
      bolus: CustomPaletteColorOptions;
    };
    agp: {
      p90: ThemeColor;
      p50: ThemeColor;
      median: ThemeColor;
    };
    bgm: {
      header: ThemeColor;
      clearColumn: ThemeColor;
      darkColumn: ThemeColor;
      orangeDarkBackground: ThemeColor;
      redBackground: ThemeColor;
      orangeBackground: ThemeColor;
      greenBackground: ThemeColor;
      purpleText: ThemeColor;
      activityIcon: ThemeColor;
      purpleBackground: ThemeColor;
    };
  }

  interface TypographyVariants {
    subtitleLarge: React.CSSProperties;
    subtitle: React.CSSProperties;
    bodyLarge: React.CSSProperties;
    body: React.CSSProperties;
    bodySmall: React.CSSProperties;
    button: React.CSSProperties;
    buttonSmall: React.CSSProperties;
    caption: React.CSSProperties;
    captionSmall: React.CSSProperties;
  }

  interface TypeText {
    title: string;
  }
}

declare module '@mui/material/Typography' {
  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    subtitleLarge: React.CSSProperties;
    subtitle: React.CSSProperties;
    bodyLarge: React.CSSProperties;
    body: React.CSSProperties;
    bodySmall: React.CSSProperties;
    button: React.CSSProperties;
    buttonSmall: React.CSSProperties;
    caption: React.CSSProperties;
    captionSmall: React.CSSProperties;
  }

  interface TypographyPropsVariantOverrides {
    h1: true;
    h2: true;
    h3: true;
    h4: true;
    h5: true;
    h6: true;

    subtitle1: false;
    subtitle2: false;
    subtitleLarge: true;
    subtitle: true;

    body1: false;
    body2: false;
    bodyLarge: true;
    body: true;
    bodySmall: true;

    button: true;
    buttonSmall: true;

    caption: true;
    captionSmall: true;

    overline: false;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    red: true;
    orange: true;
    blue: true;
    green: true;
    pink: true;
    purple: true;
    violet: true;
    neutral: true;
  }

  interface ChipPropsVariantOverrides {
    filled: true;
    inverted: true;
    outlined: true;
  }
}

declare module '@mui/material/Switch' {
  interface SwitchPropsColorOverrides {
    green: true;
  }
}

export const baseTypographyOptions: (
  palette: Palette,
) => TypographyOptions = palette => ({
  fontFamily: 'NunitoSans, Roboto, sans-serif',
  h1: { fontSize: FontSizes.h1, fontWeight: 700, color: palette.text.title },
  h2: { fontSize: FontSizes.h2, fontWeight: 700, color: palette.text.title },
  h3: { fontSize: FontSizes.h3, fontWeight: 700, color: palette.text.title },
  h4: { fontSize: FontSizes.h4, fontWeight: 700, color: palette.text.title },
  h5: { fontSize: FontSizes.h5, fontWeight: 700, color: palette.text.title },
  h6: { fontSize: FontSizes.h6, fontWeight: 700, color: palette.text.primary },
  subtitleLarge: {
    fontSize: FontSizes.subtitleLarge,
    fontWeight: 700,
    color: palette.text.primary,
  },
  subtitle: {
    fontSize: FontSizes.subtitle,
    fontWeight: 700,
    color: palette.text.primary,
  },
  bodyLarge: {
    fontSize: FontSizes.bodyLarge,
    fontWeight: 400,
    color: palette.text.primary,
  },
  body: {
    fontSize: FontSizes.body,
    fontWeight: 400,
    color: palette.text.primary,
  },
  bodySmall: {
    fontSize: FontSizes.bodySmall,
    fontWeight: 600,
    color: palette.text.primary,
  },
  button: {
    fontSize: FontSizes.button,
    fontWeight: 600,
    color: palette.text.primary,
    textTransform: 'none',
  },
  buttonSmall: {
    fontSize: FontSizes.buttonSmall,
    fontWeight: 600,
    color: palette.text.secondary,
    textTransform: 'none',
  },
  caption: {
    fontSize: FontSizes.caption,
    fontWeight: 600,
    color: palette.text.secondary,
  },
  captionSmall: {
    fontSize: FontSizes.captionSmall,
    fontWeight: 700,
    lineHeight: 1,
    color: palette.text.secondary,
  },
});

export const baseComponentsOverrides: Components<Theme> = {
  MuiCssBaseline: {
    styleOverrides: `
    ${FontDefinitions.NunitoSans}
    `,
  },
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        subtitleLarge: 'p',
        subtitle: 'p',
        bodyLarge: 'p',
        body: 'p',
        bodySmall: 'p',
        button: 'p',
        buttonSmall: 'p',
        caption: 'p',
        captionSmall: 'p',
      },
    },
  },
  MuiChip: {
    variants: [],
    styleOverrides: {
      root: ({ theme, ownerState: { variant, color } }) => {
        if (variant === 'inverted' && color && color !== 'default') {
          return {
            backgroundColor: theme.palette[color].contrastText,
            color: theme.palette[color].dark,
          };
        }
        return undefined;
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(4, 0),
      }),
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(4, 12),
      }),
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(4, 12),
      }),
    },
  },
  MuiCardActions: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(4, 12),
      }),
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        borderRadius: '2rem',
      },
    },
    defaultProps: {
      PaperProps: { onClick: (e: MouseEvent) => e.stopPropagation() },
    },
  },
  MuiDialogTitle: {
    defaultProps: {
      textAlign: 'center',
    },
  },
  MuiDrawer: {
    defaultProps: {
      PaperProps: { onClick: (e: MouseEvent) => e.stopPropagation() },
    },
  },
  MuiModal: {
    defaultProps: {
      onClick: e => e.stopPropagation(),
    },
  },
  MuiButton: {
    styleOverrides: {
      root: () => ({
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&:active': {
          boxShadow: 'none',
        },
        '&:focus': {
          boxShadow: 'none',
        },
      }),
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: () => ({
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&:active': {
          boxShadow: 'none',
        },
        '&:focus': {
          boxShadow: 'none',
        },
      }),
    },
  },
};

/**
 * Only pick keys from Palette that have type PaletteColor
 */
export type PaletteColors = {
  [K in keyof Palette]: Palette[K] extends PaletteColor ? K : never;
}[keyof Palette];
